



















































import { Vue, Component, Watch } from 'vue-property-decorator';
import { PageBase } from '@/core/models/shared';
import { Faina } from '@/core/models/operacional';
import { FainaService } from '@/core/services/operacional';
import { AlertExcludeQuestion, AlertSimpleErr } from '@/core/services/shared/AlertService';

@Component
export default class ListaFaina extends PageBase {
  search: any = '';
  loading: boolean = false;
  dialogCadastro: boolean = false;
  totalLista: number = 0;
  lista: any[] = [];
  options: any = {
    itemsPerPage: 15
  };
  headers: any[] = [
    { text: '',value:'actions' ,sortable: false },
    { text: 'Nome', value: 'nome' },
    { text: 'Nome (Inglês)', value: 'nomeIngles' },
  ];
  item = new Faina();
  service = new FainaService();

  @Watch('options', { deep: true })
  Atualizar(){
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers).then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
      err => AlertSimpleErr("Aviso!", err)
    )
    .finally(() => (this.loading = false));
  }

  AbrirDialogCadastro(item?: Faina){  
    if(item){
      this.service.ObterPorId(item.id).then(
        res=>{
          this.item = res.data;
          this.dialogCadastro = true;
        },
        err => AlertSimpleErr("Aviso!", err)
      )
    }
    else{
      this.item = new Faina();
      this.item.empresaId = this.app.empresaId;
      this.dialogCadastro = true;
    }
  }

  Excluir(item: Faina){
    const context = this;
    const excluir = function () {
      return new Promise( async function (resolve, reject){
        await context.service.Excluir(item.id).then(
          res => {
            if(res.status == 200){
              return resolve(res.data);
            }
          },
          err => {
            AlertSimpleErr("Aviso", err);
          }
        ).finally(() => {
          context.Atualizar();
        })
      });
    }
    AlertExcludeQuestion(excluir);
  }
}

